@media (max-width: $screen-lg) {
    // lg-md view

    html {

    }

    .wrapper {
    	min-width: 1110px;
    	max-width: 100%;
    }

    .wrapper-inside {
        width: 1110px;
    }

    /* Header
    -----------------------------------------------------------------------------*/
    .header {

    }

    /* Middle
    -----------------------------------------------------------------------------*/
    .content {
        padding: 0 0 $footer-size-lg;
    }

    /* Footer
    -----------------------------------------------------------------------------*/
    .footer {
        margin: -$footer-size-lg auto 0;
        height: $footer-size-lg;
    }
}

@media (max-width: $screen-md) {
    // md-view
    html {
        font-size: $font-size-base-md;
        line-height: $line-height-computed-md;
    }

    .wrapper {
    	min-width: 590px;
    	max-width: 100%;
    }

    .wrapper-inside {
        width: 95.203%;
    }

    /* Header
    -----------------------------------------------------------------------------*/
    .header {

    }

    /* Middle
    -----------------------------------------------------------------------------*/
    .content {
        padding: 0 0 $footer-size-md;
    }

    /* Footer
    -----------------------------------------------------------------------------*/
    .footer {
        margin: -$footer-size-md auto 0;
        height: $footer-size-md;
    }
}
