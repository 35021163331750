@import "parameters";
@import "fonts";
@import "mixins";
@import "bootstrap/text-overflow";
@import "bootstrap/text-emphasis";
@import "bootstrap/background-variant";
@import "bootstrap/clearfix";
@import "bootstrap/normalize";
@import "bootstrap/responsive-visibility";
@import "includes/mobile_menu";
@import "includes/slick";
@import "includes/forms";
@import "includes/custom_scroll";
@import "includes/custom_tabs";
@import "includes/pagination";
@import "includes/fancybox/jquery.fancybox.min.css";
@import "includes/popups/custom_fancybox";
@import "includes/selectric/selectric";


/* Eric Meyer's CSS Reset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {

}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* End of Eric Meyer's CSS Reset */

html {
	height: 100%;
	font-family: $main_font;
	font-style: normal;
	font-weight: normal;
	font-size: $font-size-base;
	line-height: $line-height-computed;
	color: $gray-base;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
	display: block;
}
body {
	height: 100%;
	&.non-scrollable {
		height: 100vh;
		overflow: hidden;
	}
}

.wrapper {
	min-width: 1470px;
	max-width: 100%;
	margin: 0 auto;
	min-height: 100%;
	height: auto;
}

.wrapper-inside {
    width: 1470px;
    margin: 0 auto;
}

/* Basics
-----------------------------------------------------------------------------*/
img, svg {
    vertical-align: top;
}

.clearfix {
    clear: both !important;
}

.hidden {
    display: none !important;
}

.invisible {
    visibility: hidden !important;
}

a {
	color: $gray-dark;
	text-decoration: none;
	transition: all 300ms;
	&:hover {
		color: $brand-primary;
		transition: all 300ms;
	}
}


/* Header
-----------------------------------------------------------------------------*/
.header {
	background: transparent;
	height: $header-size;
	@import "includes/base/header";
	&.floating {
		position: relative;
		height: 0;
		.header-holder  {
			position: absolute;
			z-index: 3;
			left: 0;
			top: 0;
		}
	}
}


/* Middle
-----------------------------------------------------------------------------*/
.content {
	padding: 0 0 $footer-size;
}

/* Footer
-----------------------------------------------------------------------------*/
.footer {
	margin: -$footer-size auto 0;
	height: $footer-size;
	background: $gray-base;
	color: white;
	@import "includes/base/footer";
}

@import "bootstrap/responsive-utilities";
@import "bootstrap/type";
@import "bootstrap/buttons";

@import "media/tablet/base";
@import "media/mobile/base";
