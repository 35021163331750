@media (max-width: $screen-lg) {
    // lg-md view
    .footer-holder { 

        .footer-main { 
            
            .footer-main-holder { 
                align-items: flex-start;
                padding: 31px 0 30px;
                .left {

                    .logo {
                        margin-right: 51px;
                    }

                    .footer-menu { 
                        .footer-menu-holder { 
                            nav {
                                ul {
                                    gap: 15px;
                                    li {

                                    }
                                }
                            }
                        }
                    }
                }

                .right { 
                    width: 280px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    .phone { 
                        .phone-holder {
                            padding: 22px 0 0;
                        }
                    }

                    .socials { 
                        margin-right: 0;
                        .socials-holder { 
                            padding: 22px 0 0;
                        }
                    }

                    .project {
                        .project-holder { 
                            padding: 33px 0 0;
                            .btn {
                                height: 50px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .footer-holder { 

        .footer-main { 
            
            .footer-main-holder { 
                align-items: flex-end;
                .left {
                    width: 200px;
                    .logo {
                        .logo-holder { 
                            a {
                                svg {
                                    width: 160px;
                                    height: 50px;
                                }
                            }
                        }
                    }

                    .footer-menu { 
                        .footer-menu-holder { 
                            padding: 19px 0 0;
                            nav {
                                ul {
                                    display: inline-block;
                                    li {
                                        width: 100%;
                                        font-size: 14px;
                                        margin: 0 0 11px;
                                        &:last-of-type {
                                            margin: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .right {
                    .phone {
                        .phone-holder {
                            padding: 22px 0 0;
                            a {
                                font-size: 14px;
                            }
                        }
                    }

                    .project { 
                        .project-holder { 
                            padding: 24px 0 0;
                            .btn {
                                width: 200px;
                                height: 45px;
                                font-size: 14px;
                            }
                        }
                    }
                }

            }
        }

        .footer-bottom { 
            .footer-bottom-holder {
                .left { 

                    .copyrigth { 
                        font-size: 12px;
                    }

                    .policy { 
                        font-size: 12px;
                    }
                }

                .right { 
                    .studio {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
