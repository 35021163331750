@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .pagination {
        ul {
            >li {
                a {
                    width: 35px;
                    height: 35px;
                    font-size: 14px;
                }

                &.middle {
                    a {
                     
                    }
                }
            }
        }
    }
}
