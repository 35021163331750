@media (max-width: $screen-sm) {
    // sm-view
    form {
        .action {
            .submit {
                .btn {
                    font-size: 12px;
                    padding: 11px 10px;
                }
            }
            .agree-col {
                font-size: 10px;
            }
        }
        .action.full {
            margin: 5px 0 0;
            .agree-col {
                margin: 8px 0 0;
            }
        }

        .form-field {
            label.input {
                &.addon {
                    input {
                        padding: 0px 10px 0 43px;
                    }
                }

                input, textarea {
                    font-size: 12px;
                    height: 40px;
                    @include placeholder {
                        font-size: 12px;
                    }
                }

                .addon {
                    svg {
                        width: 14px;
                        height: 14px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
