@media (max-width: $screen-sm) {
    // sm-view
    .slick-arrow {
        width: 20px;
        height: 20px;
        line-height: 20px;
        &.slick-prev, &.prev {
            left: -10px;
        }
        &.slick-next, &.next {
            right: -10px;
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
