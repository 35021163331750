&.white-contrast {
    .header-holder { 
        .header-main { 
            .header-main-holder { 
                .left { 
                
                    .left-holder { 
                        .mobile-menu {
                            a {
                                svg.gray {
                                    display: block;
                                }

                                svg.white {
                                    display: none;
                                }
                            }
                        }

                        .logo { 
                            a {
                                background: #F9F9F9;
                            }
                        }
                    }
                }

                .center { 
                    .center-holder { 
                        background: #F9F9F9;
                        .menu { 

                            nav { 
    
                                >ul { 
                                    >li.has-sub-menu { 
                                        .sub-menu { 
                                            .sub-menu-holder { 
                                                background: #F9F9F9;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.header-holder { 
    display: inline-block;
    width: 100%;
	.header-main { 
        display: inline-block;
        width: 100%;
        padding: 19px 0 19px;
		.header-main-holder { 
            display: flex;
            width: 100%;
            gap: 10px;
			.left { 
                
				.left-holder { 

                    .mobile-menu {
                        float: left;
                        margin-right: 10px;
                        a {
                            svg.gray {
                                display: none;
                            }

                            svg.white {
                                display: block;
                            }
                        }
                    }
  
					.logo { 
                        float: left;
                        width: 175px;
                        height: 55px;
						a { 
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: white;
                            width: 100%;
                            height: 100%;
							border-radius: 8px;
                            svg {
                                max-width: 100%;
                            }
						}
					}
				}
			}

			.center { 
                flex-grow: 1;
                height: auto;
				.center-holder { 
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: auto;
                    background: white;
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                    padding: 0 25px;
                    border-radius: 8px;
					.menu { 

						nav { 

							>ul { 
                                display: flex;
                                gap: 25px;
                                padding: 0;
                                margin: 0;
                                list-style: none;
								>li.has-sub-menu { 
                                    position: relative;
                                    &:hover {
                                        a {
                                            .icon {
                                                svg {
                                                    transform: rotate(180deg);
                                                    transition: transform 300ms;
                                                }
                                            }
                                        }
                                        .sub-menu { 
                                            opacity: 1;
                                            max-height: 300px;
                                            overflow: hidden;
                                            transition: all 700ms;
                                        }
                                    }
									a { 
                                        position: relative;
                                        padding-right: 13px;
                                        .icon {
                                            position: absolute;
                                            right: 0;
                                            top: 9px;
                                            svg {
                                                transition: transform 300ms;
                                            }
                                        }
									}

									.sub-menu { 
                                        position: absolute;
                                        z-index: 2;
                                        left: -25px;
                                        top: 100%;
                                        width: 190px;
                                        max-height: 0;
                                        overflow: hidden;
                                        opacity: 0;
                                        transition: all 500ms;
										.sub-menu-holder { 
                                            display: inline-block;
                                            width: 100%;
                                            margin: 27px 0 0;
                                            background: white;
                                            border-radius: 8px;
                                            box-sizing: border-box;
                                            padding: 20px 25px;
											ul { 
                                                list-style: none;
                                                padding: 0;
                                                margin: 0;
												li { 
                                                    width: 100%;
                                                    margin: 0 0 10px;
                                                    &:last-of-type {
                                                        margin: 0;
                                                    }
													a { 
                                                        font-size: 16px;
                                                        font-style: normal;
                                                        font-weight: 400;
                                                        line-height: normal;
                                                        color: $gray-base;
                                                        &:hover {
                                                            color: $brand-primary;
                                                        }
													}
												}
											}
										}
									}
								}

								>li { 
                                    float: left;
                                    padding: 0;
									a { 
                                        display: inline-block;
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                        color: $gray-base;
                                        &:hover {
                                            color: $brand-primary;
                                        }
									}
								}
							}
						}
					}

					.catalog-download { 

						.btn { 
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            padding: 6px 13px;
                            border-radius: 5px;
						}
					}

					.phone { 

						a { 
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            text-transform: uppercase;
                            color: $gray-base;
                            &:hover {
                                color: $brand-primary;
                            }
							.icon { 
                                float: left;
                                margin-right: 10px;
                                padding: 2px 0 0;
								svg { 
                                    width: 18px;
                                    height: 18px;
								}
							}

							.text { 
                                float: left;
							}
						}
					}

					.socials { 
                        margin: 0 -12.5px;
						.social { 
                            float: left;
                            padding: 0 12.5px;
							a { 
                                display: inline-block;
                                width: 24px;
                                height: 25px;
                                color: $brand-primary;
                                &:hover {
                                    color: $brand-primary-accent;
                                }
								svg { 
                                    width: 100%;
                                    height: 100%;
								}
							}
						}
					}
				}
			}

			.right { 
           
				.right-holder { 
          
					.project { 
                        width: 200px;
                        height: 55px;
						.btn { 
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 0;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            border-radius: 8px;
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/base/header";
@import "../../media/mobile/includes/base/header";
