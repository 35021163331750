@media (max-width: $screen-lg) {
    // lg-md view
    form {
        .action {
            .agree-col {
                font-size: 12px;
            }
        }
        .action.full {
            .agree-col {
                margin: 13px 0 0;
            }
        }

        .form-field {
            margin-bottom: 8px;
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    form {
        .action {
            .agree-col {

            }

            .submit {
                .btn {
                    font-size: 14px;
                    padding: 12px 10px;
                }
            }
        }
        .action.full {
            margin: 5px 0 0;
        }
        
        .form-field {
            label.input {
                input, textarea {
                    font-size: 14px;
                    height: 45px;
                    @include placeholder {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
