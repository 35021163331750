.pagination {

	ul {
        display: inline-block;
        list-style: none;
        margin: 0 -2.5px;
        >li {
            float: left;
            margin: 0 2.5px;
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                border: 2px solid $bg-secondary;
                background-color: $bg-secondary;
                box-sizing: border-box;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                color: $gray-base;
                text-decoration: none;
                border-radius: 3px;
                transition: all 300ms;
                &:hover {
                    border-color: $gray-base;
                    background-color: $gray-base;
                    color: white;
                    transition: all 300ms;
                }
            }

            &.current {
                a {
                    pointer-events: none;
                    touch-action: none;
                    border-color: $gray-base;
                    background-color: $gray-base;
                    color: white;
                    transition: all 300ms;
                }
            }
        
            &.middle {
                a {
                    color: $gray-base;
                    background: none;
                    border: none;
                    width: auto;
                    align-items: flex-end;
                    &:hover {
                        color: $brand-primary;
                        transition: all 300ms;
                    }
                }
            }
            
            &.next, &.prev {
                margin: 0 3.5px;
                &.disabled {
                    a {
                        color: #EDEDED;
                        pointer-events: none;
                        touch-action: none;
                    }
                }
    			a {
                    color: $gray-base;
                    background: none;
                    border: none;
                    width: auto;
    				svg {
                        width: 8px;
                        height: 14px;
    				}
                    &:hover {
                        color: $brand-primary;
                        transition: all 300ms;
                    }
    			}
    		}
            &.next {
                a {

                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
    			
        }



	}
}

@import "../media/tablet/includes/pagination";
@import "../media/mobile/includes/pagination";
