@media (max-width: $screen-sm) {
    // sm-view
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
    line-height: $headings-line-height-xs;

    }

    h1, .h1,
    h2, .h2,
    h3, .h3 {
        
    }
    h4, .h4,
    h5, .h5,
    h6, .h6 {
        
    }

    h1, .h1 { font-size: $font-size-h1-xs; }
    h2, .h2 { font-size: $font-size-h2-xs; }
    h3, .h3 { font-size: $font-size-h3-xs; }
    h4, .h4 { font-size: $font-size-h4-xs; }
    h5, .h5 { font-size: $font-size-h5-xs; }
    h6, .h6 { font-size: $font-size-h6-xs; }

    p {
        font-size: $font-size-base-xs;
        line-height: $line-height-computed-xs;
        margin: 0 0 15px;
    }

    .lead {
        margin-bottom: $line-height-computed-xs;
        font-size: floor(($font-size-base-xs * 1.15));
    }

    p.accent {
        font-size: $font-size-large-xs;
        line-height: floor(($font-size-large-xs * $line-height-base-xs));
    }
    p.small {
        font-size: $font-size-small-xs;
        line-height: floor(($font-size-small-xs * $line-height-base-xs));
    }

    small {
        font-size: $font-size-small-xs;
    }
    
    dl {
        margin-top: 0; // Remove browser default
        margin-bottom: $line-height-computed-xs;
      }
    dt,
    dd {
        line-height: $line-height-base-xs;
    }

    blockquote {
        padding: floor(calc($line-height-computed-xs / 2)) $line-height-computed-xs;
        margin: 0 0 $line-height-computed-xs;
        font-size: $blockquote-font-size;
    }

    address {
        margin-bottom: $line-height-computed-xs;
        font-style: normal;
        line-height: $line-height-base-xs;
    }

    .page-heading {
        margin-bottom: $line-height-computed-xs;
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
