@import "../mixins";

$menu-width: 320px;

/*Mobile menu
-----------------------------------------------------------------------------*/
.mobile-menu {
	.mobile-menu-holder {
		.menu-background {
			position: fixed;
			z-index: 1201;
			top: 0;
			left: 0;
			background: $gray-base;
			opacity: 0.64;
			width: 100%;
			height: 100%;
			@include transition-easy-in-out(250ms);
			&.animated {
				opacity: 0;
				@include transition-easy-in-out(250ms);
			}
			&.closed {
				display: none;
			}
		}
		.menu-body {
			position: fixed;
			z-index: 1202;
			top: 0;
			left: 0;
			display: inline-block;
			float: left;
			width: $menu-width;
			height: 100%;
			box-sizing: border-box;
			padding: 0;
			background: white;
			opacity: 1;
			transform: translateX(0);
			overflow-y: hidden;
			@include transition-easy-in-out(350ms);
			&.animated {
				transform: translateX(-$menu-width);
				@include transition-easy-in-out(350ms);
			}
			&.closed {
				pointer-events: none;
				touch-action: none;
			}
            &.mobile-offset {

            }
			>.heading {
				float: left;
				width: 100%;
				box-sizing: border-box;
				&.shadow {
					box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
					.heading-holder { 
						border: none;
					}
				}
				.heading-holder { 
					display: inline-block;
					width: 100%;
					height: 81px;
					background: white;
					box-sizing: border-box;
					padding: 20px 15px;
					border-bottom: 1px solid #EDEDED;
					overflow: hidden;
					.text {
						width: calc(100% - 40px);
						float: left;
						height: 40px;
						display: flex;
						align-items: center;
					}

					.action { 
						float: left;
						width: 40px;
						height: 40px;
						.action-holder { 
							width: 100%;
							height: 100%;
							position: relative;
							.close, .back { 
								position: absolute;
								left: 0;
								top: 0;
								z-index: 2;
								&.closed {
									pointer-events: none;
									touch-action: none;
									opacity: 0;
								}
								a { 
									display: inline-block;
									width: 100%;
									height: 100%;
									svg { 
										width: 100%;
										height: 100%;
									}
								}
							}

							.close {

							}
				
							.back { 
								
							}
						}
					}
				
					.project { 
						float: right;
						width: 146px;
						height: 40px;
						.project-holder { 
							width: 100%;
							height: 100%;
							.btn { 
								display: flex;
								align-items: center;
								justify-content: center;
								width: 100%;
								height: 100%;
								font-size: 12px;
								font-style: normal;
								font-weight: 600;
								line-height: normal;
								padding: 0;
								border-radius: 8px;
							}
						}
					}
				}
			}

			.main-body {
				float: left;
				width: 100%;
				box-sizing: border-box;
				height: calc(100vh - 81px - 119px);
				overflow-y: auto;
				overflow-x: hidden;
				position: relative;
				.main-body-holder {
					display: inline-block;
					width: 100%;
					nav { 
						&.sub-menu-js-menu-body {
							ul {
								li.has-sub-menu.open { 
									.sub-menu { 
										left: 0;
										transition: all 500ms;
									}
								}
							}
						}
						ul { 
							list-style: none;
							padding: 0;
							margin: 0;
							li.has-sub-menu { 
					
								a { 
									position: relative;
									.icon { 
										position: absolute;
										right: 15px;
										top: 50%;
										transform: translateY(-50%);
										svg { 
		
										}
									}
								}
					
								.sub-menu { 
									position: absolute;
									left: -100%;
									top: 0;
									height: 100%;
									width: 100%;
									background-color: white;
									transition: all 500ms;
									.sub-menu-holder { 
										display: inline-block;
										width: 100%;
										ul { 
					
											li { 
					
												a { 
					
												}
											}
										}
									}
								}
							}
					
							li { 
								float: left;
								width: 100%;
								height: 45px;
								a { 
									display: flex;
									width: 100%;
									height: 100%;
									align-items: center;
									box-sizing: border-box;
									padding: 0 15px;
									font-size: 12px;
									font-style: normal;
									font-weight: 400;
									line-height: 140%; 
									color: black;
								}
							}
						}
					}
				}
			}

			.footing {
				float: left;
				width: 100%;
				box-sizing: border-box;
				position: relative;
				z-index: 10001;
				&.shadow {
					box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.08);
					.footing-holder {
						border: none;
					}
				}
				.footing-holder {
					display: inline-block;
					width: 100%;
					height: 119px;
					overflow: hidden;
					background: white;
					box-sizing: border-box;
					padding: 20px 15px;
					border-top: 1px solid #EDEDED;
					.contacts { 
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin: 0 0 20px;
						.phone { 
							
							.phone-holder { 
					
								a { 
									display: flex;
									align-items: center;
									gap: 10px;
									font-size: 12px;
									font-style: normal;
									font-weight: 600;
									line-height: normal;
									color: $gray-base;
									.icon { 
										width: 16px;
										height: 16px;
										svg { 
											width: 100%;
											height: 100%
										}
									}
					
									.text { 
					
									}
								}
							}
						}
					
						.socials { 
					
							.socials-holder { 
								display: flex;
								align-items: center;
								gap: 25px;
								.social { 
									
									a { 
										display: inline-block;
										width: 24px;
										height: 24px;
										color: $brand-primary;
										svg { 
											width: 100%;
											height: 100%
										}
									}
								}
							}
						}
					}
					
					.catalog-download { 
						display: inline-block;
						width: 100%;
						.btn { 
							display: inline-block;
							width: 100%;
							text-align: center;
							border-radius: 5px;
							font-size: 12px;
							font-style: normal;
							font-weight: 600;
							line-height: normal;
							padding: 8px 0;
						}
					}
				}
			}
		}
	}
}

@import "../media/mobile/includes/mobile_menu";
@import "../media/tablet/includes/mobile_menu";
