.footer-holder { 

	.footer-main { 

		.footer-main-holder { 
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 40px 0 40px;
            .left { 
                
                .logo { 
                    margin-right: 61px;
                    float: left;
                    .logo-holder { 

                        a { 
                            display: inline-block;
                            svg { 
                                width: 210px;
                                height: 66px;
                            }
                        }
                    }
                }

                .footer-menu { 
                    float: left;
                    .footer-menu-holder { 
                        padding: 22px 0 0;
                        nav { 

                            ul { 
                                display: flex;
                                gap: 25px;
                                list-style: none;
                                padding: 0;
                                margin: 0;
                                li { 
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 140%; 
                                    a { 
                                        color: white;
                                        &:hover {
                                            color: $brand-primary;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .right { 

                .phone { 
                    float: left;
                    margin-right: 30px;
                    .phone-holder { 
                        padding: 17px 0 0;
                        a { 
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            text-transform: uppercase;
                            color: white;
                            &:hover {
                                color: $brand-primary;
                            }
							.icon { 
                                float: left;
                                margin-right: 10px;
                                padding: 2px 0 0;
								svg { 
                                    width: 18px;
                                    height: 18px;
								}
							}

							.text { 
                                float: left;
							}
						}
                    }
                }

                .socials { 
                    float: left;
                    margin-right: 31px;
                    .socials-holder { 
                        margin: 0 -12.5px;
                        padding: 16px 0 0;
						.social { 
                            float: left;
                            padding: 0 12.5px;
							a { 
                                display: inline-block;
                                width: 24px;
                                height: 25px;
                                color: $brand-primary;
                                &:hover {
                                    color: $brand-primary-accent;
                                }
								svg { 
                                    width: 100%;
                                    height: 100%;
								}
							}
						}
                    }
                }

                .project { 
                    float: left;
                    .project-holder { 

                        .btn { 
                            width: 218px;
                            height: 55px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 0;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            border-radius: 8px;
                        }
                    }
                }
            }
        }
	}

	.footer-bottom { 
        border-top: 1px solid #292929;
        padding: 19px 0 0;
		.footer-bottom-holder { 
            display: flex;
            justify-content: space-between;
            .left { 

                .copyrigth { 
                    float: left;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; 
                    padding: 2px 0 0;
                    margin-right: 61px;
                    .copyright-holder { 

                    }
                }

                .policy { 
                    float: left;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; 
                    padding: 2px 0 0;
                    a { 
                        color: white;
                        &:hover {
                            color: $brand-primary;
                        }
                    }
                }
            }

            .right { 
                
                .studio { 
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; 
                    .studio-holder { 

                        a { 
                            display: flex;
                            align-items: center;
                            color: white;
                            &:hover {
                                color: $brand-primary;
                            }
                            .text { 
                                margin-right: 10px;
                            }

                            .icon { 


                            }
                        }
                    }
                }
            }
        }
	}
}

@import "../../media/tablet/includes/base/footer";
@import "../../media/mobile/includes/base/footer";
