.fancybox-container.custom-fancybox {
    .fancybox-content {
        padding: 0;
        overflow: initial;
    }
    button.fancybox-button.fancybox-close-small {
        display: none;
    }
}

.popup {
    position: relative;
    .custom-close {
        position: absolute;
        right: 20px;
        top: 20px;
        a {
            display: inline-block;
            text-decoration: none;
            svg {
                width: 18px;
                height: 18px;
                color: #BBB;
                transition: all 300ms;
            }
            &:hover {
                svg {
                    color: $gray;
                    transition: all 300ms;
                }
            }
        }
    }
}
