@media (max-width: $screen-sm) {
    // sm-view
    .header-holder { 
        .header-main {
            padding: 20px 0 19px;
            .header-main-holder { 
                .left { 
                    width: 100%;
                    .left-holder { 
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        .mobile-menu {
                            a {
                                svg {
                                    width: 40px;
                                    height: 40px;
                                }
                            }
                        }

                        .logo { 
                            width: 146px;
                            height: 40px;
                        }
                    }

                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
