@media (max-width: $screen-lg) {
    // lg-md view
    .header-holder { 
        .header-main {
            .header-main-holder { 
                .left { 
                  
                    .left-holder { 
                        .logo {
                            width: 146px;
                            height: 50px;
                            a {
                                svg {
                                    max-width: none;
                                    width: 116px;
                                    height: 17px;
                                }
                            }
                        }
                    }
                }

                .center { 
                    .center-holder { 
                        .menu { 
                            margin-right: 22px;
                            nav { 
    
                                >ul { 
                                    gap: 20px;
                                    >li { 
                                        a {
                                            font-size: 14px;  
                                        }
                                    }
                                }
                            }
                        }

                        .phone { 
                            a.btn {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 35px;
                                height: 35px;
                                padding: 0;
                            }
                        }
                    }
                }
                

                .right { 

                    .right-holder { 
          
                        .project { 
                            width: 176px;
                            height: 50px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .header-holder { 
        .header-main { 
            .header-main-holder { 
                justify-content: space-between;
                .left { 
                    .left-holder { 
                        .mobile-menu {
                            
                        }

                        .logo { 
                            width: 146px;
                            height: 45px;
                        }
                    }
                }

                .right { 

                    .right-holder { 
          
                        .project { 
                            width: 158px;
                            height: 45px;
                            .btn {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}
