@media (max-width: $screen-sm) {
    // sm-view
    .footer-holder { 

        .footer-main { 
            
            .footer-main-holder { 
                align-items: flex-start;
                flex-direction: column;
                .left {
                    width: 100%;
                    margin: 0 0 26px;
                    .logo {
                        width: 100%;
                        .logo-holder { 

                        }
                    }

                    .footer-menu { 
                        width: 100%;
                        .footer-menu-holder { 
                            nav {
                                ul {
                                    li {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }

                .right {
                    width: 100%;
                    justify-content: flex-start;
                    vertical-align: middle;
                    .phone {
                        .phone-holder {
                            padding: 0;
                            a {
                                font-size: 12px;
                                .text {
                                    padding: 5px 0 0;
                                }
                            }
                        }
                    }

                    .socials { 
                        .socials-holder { 
                            padding: 0;
                        }
                    }

                    .project { 
                        width: 100%;
                        .project-holder { 
                            width: 100%;
                            padding: 15px 0 0;
                            .btn {
                                width: 100%;
                                height: 40px;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }

        .footer-bottom { 
            .footer-bottom-holder { 
                flex-direction: column;
                justify-content: flex-start;
                .left { 
                    width: 100%;
                    margin: 0 0 16px;
                    .copyrigth { 
                        width: 100%;
                        font-size: 10px;
                        padding: 0;
                        margin: 0 0 10px;
                    }

                    .policy { 
                        width: 100%;
                        font-size: 10px;
                        padding: 0;
                    }
                }

                .right { 
                    width: 100%;
                    .studio { 
                        font-size: 8px;
                        .studio-holder { 
                            a {
                                color: $gray-dark;
                                .text {
                                    margin-right: 5px;
                                }
                                .icon {
                                    svg {
                                        width: 25px;
                                        height: 18px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
