@media (max-width: $screen-sm) {
    // sm-view
    .pagination {
        ul {
            >li {
                a {
                    width: 24px;
                    height: 24px;
                    font-size: 12px;
                }

                &.middle {
                    a {
                     
                    }
                }

                &.next, &.prev {
                    a {
                        svg {
                            width: 6px;
                            height: 10px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
